import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableDaftarAngsuran } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader } from "../../../components";
import { Colors } from "../../../constants/styles";
import { Form } from "react-bootstrap";
import {
  Box,
  Typography,
  Divider,
  Pagination,
  ButtonGroup,
  Button,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import * as XLSX from "xlsx";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const TampilDaftarAngsuran = () => {
  let navigate = useNavigate();
  const { user, dispatch } = useContext(AuthContext);
  const { screenSize } = useStateContext();
  const [isFetchError, setIsFetchError] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [jualsData, setJualsData] = useState([]);
  const [isOpenLaporan, setIsOpenLaporan] = useState(false);

  const [value, setValue] = useState("Semua");
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  const handleChangeFilter = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (user.tipeUser === "DESKCALL") {
      getJualsAllCabangData();
    } else {
      getJualsData();
    }
  }, [value, page, searchTerm]);

  const getJualsAllCabangData = async () => {
    setLoading(true);
    try {
      let allJuals;
      switch (value) {
        case "Sudah Lunas":
          allJuals = await axios.post(
            `${tempUrl}/jualsForDaftarAngsuranSudahLunasAllCabangPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
            }
          );
          break;
        case "Penarikan":
          allJuals = await axios.post(
            `${tempUrl}/jualsForDaftarAngsuranPenarikanAllCabangPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
            }
          );
          break;
        case "Titipan":
          allJuals = await axios.post(
            `${tempUrl}/jualsForDaftarAngsuranTitipanAllCabangPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
            }
          );
          break;
        case "Sweeping":
          allJuals = await axios.post(
            `${tempUrl}/jualsForDaftarAngsuranSweepingAllCabangPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
            }
          );
          break;
        case "MotorHilang":
          allJuals = await axios.post(
            `${tempUrl}/jualsForDaftarAngsuranMotorHilangAllCabangPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
            }
          );
          break;
        default:
          allJuals = await axios.post(
            `${tempUrl}/jualsForDaftarAngsuranAllCabangPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
            }
          );
          break;
      }

      setQuery(searchTerm);
      setJualsData(allJuals.data.juals);
      setPage(allJuals.data.page);
      setPages(allJuals.data.totalPage);
      setRows(allJuals.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getJualsData = async () => {
    setLoading(true);
    try {
      let allJuals;
      switch (value) {
        case "Sudah Lunas":
          allJuals = await axios.post(
            `${tempUrl}/jualsForDaftarAngsuranSudahLunasPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "Penarikan":
          allJuals = await axios.post(
            `${tempUrl}/jualsForDaftarAngsuranPenarikanPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "Titipan":
          allJuals = await axios.post(
            `${tempUrl}/jualsForDaftarAngsuranTitipanPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "Sweeping":
          allJuals = await axios.post(
            `${tempUrl}/jualsForDaftarAngsuranSweepingPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "MotorHilang":
          allJuals = await axios.post(
            `${tempUrl}/jualsForDaftarAngsuranMotorHilangPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        default:
          allJuals = await axios.post(
            `${tempUrl}/jualsForDaftarAngsuranPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
      }

      setQuery(searchTerm);
      setJualsData(allJuals.data.juals);
      setPage(allJuals.data.page);
      setPages(allJuals.data.totalPage);
      setRows(allJuals.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getLaporanAngsuransForDoc = async (
    isCustomerAktif,
    isCustomerSemua
  ) => {
    setLoading(true);
    if (isCustomerAktif) {
      let laporanAngsuransForDoc;
      if (user.tipeUser === "DESKCALL") {
        laporanAngsuransForDoc = await axios.post(
          `${tempUrl}/jualsForAngsuranLaporanCustomerAktifAllCabang`,
          {
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
      } else {
        laporanAngsuransForDoc = await axios.post(
          `${tempUrl}/jualsForAngsuranLaporanCustomerAktif`,
          {
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
      }
      downloadExcelAngsuranCustomerAktif(laporanAngsuransForDoc.data);
    } else if (isCustomerSemua) {
      let laporanAngsuransForDoc;
      if (user.tipeUser === "DESKCALL") {
        laporanAngsuransForDoc = await axios.post(
          `${tempUrl}/jualsForAngsuranLaporanCustomerSemuaAllCabang`,
          {
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
      } else {
        laporanAngsuransForDoc = await axios.post(
          `${tempUrl}/jualsForAngsuranLaporanCustomerSemua`,
          {
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
      }
      downloadExcelAngsuranCustomerSemua(laporanAngsuransForDoc.data);
    }
    setLoading(false);
  };

  const downloadExcelAngsuranCustomerAktif = (data) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `Tipe`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarLaporanAngsuranCustomerAktif.xlsx`);
  };

  const downloadExcelAngsuranCustomerSemua = (data) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `Tipe`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarLaporanAngsuranCustomerSemua.xlsx`);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Daftar Angsuran
      </Typography>
      <Box sx={buttonModifierContainer}>
        <ButtonGroup variant="contained">
          {user.tipeUser !== "DESKCALL" && (
            <Button
              color="success"
              sx={{ bgcolor: "success.light", textTransform: "none" }}
              startIcon={<AddCircleOutlineIcon />}
              size="small"
              onClick={() => {
                navigate(`/daftarAngsuran/angsuran/tambahAngsuran`);
              }}
            >
              Bayar
            </Button>
          )}
          <Button
            color="info"
            sx={{ bgcolor: "info", textTransform: "none" }}
            size="small"
            onClick={() => {
              setIsOpenLaporan(!isOpenLaporan);
            }}
          >
            Laporan
          </Button>
        </ButtonGroup>
      </Box>
      {isOpenLaporan === true && (
        <Box sx={buttonModifierContainer}>
          <ButtonGroup variant="contained">
            <Button
              color="info"
              sx={{ bgcolor: "info", textTransform: "none" }}
              size="small"
              onClick={() => getLaporanAngsuransForDoc(true, false)}
            >
              Laporan Customer Aktif
            </Button>
            <Button
              color="info"
              sx={{ bgcolor: "info.light", textTransform: "none" }}
              size="small"
              onClick={() => getLaporanAngsuransForDoc(false, true)}
            >
              Laporan Customer Semua
            </Button>
          </ButtonGroup>
        </Box>
      )}
      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Filter</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="Semua"
          value={value}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            value="Sudah Lunas"
            control={<Radio />}
            label="Sudah Lunas"
          />
          <FormControlLabel
            value="Penarikan"
            control={<Radio />}
            label="Penarikan"
          />
          <FormControlLabel
            value="Titipan"
            control={<Radio />}
            label="Titipan"
          />
          <FormControlLabel
            value="Sweeping"
            control={<Radio />}
            label="Sweeping"
          />
          <FormControlLabel
            value="MotorHilang"
            control={<Radio />}
            label="Motor Hilang"
          />
          <FormControlLabel value="Semua" control={<Radio />} label="Semua" />
        </RadioGroup>
      </FormControl>
      <Divider sx={dividerStyle} />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={{ mt: 2 }}>
        <Chip
          label="Sudah Lunas"
          sx={{
            backgroundColor: Colors.red400,
          }}
        />
        <Chip
          label="Penarikan"
          sx={{
            backgroundColor: Colors.yellow400,
            ml: 1,
          }}
        />
        <Chip
          label="Titip"
          sx={{
            backgroundColor: Colors.blue300,
            ml: 1,
          }}
        />
        <Chip
          label="Sweeping"
          sx={{
            backgroundColor: Colors.purple300,
            ml: 1,
          }}
        />
        <Chip
          label="Motor Hilang"
          sx={{
            backgroundColor: Colors.orange300,
            ml: 1,
          }}
        />
      </Box>
      <Box sx={tableContainer}>
        <ShowTableDaftarAngsuran currentPosts={jualsData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilDaftarAngsuran;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};
