import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahSuratPenarikan = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  var date = new Date();

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "13px",
  };
  const [kolektors, setKolektors] = useState([]);
  const [juals, setJuals] = useState([]);
  // Customer
  const [noSt, setNoSt] = useState(""); // I
  const [inputTglSt, setInputTglSt] = useState(date); // I
  const [noJual, setNoJual] = useState(""); // I
  const [kodeKolektor, setKodeKolektor] = useState(""); // I
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [tglAngFormatted, setTglAngFormatted] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  // Motor
  const [tipe, setTipe] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [nopol, setNopol] = useState("");
  // Biaya
  const [tglJatuhTempo, setTglJatuhTempo] = useState("");
  const [tglJatuhTempoFormatted, setTglJatuhTempoFormatted] = useState("");
  const [angPerBulan, setAngPerBulan] = useState(""); // I
  const [jmlBlnTelat, setJmlBlnTelat] = useState(""); // I
  const [dendaTunggak, setDendaTunggak] = useState("");
  const [totalDenda, setTotalDenda] = useState(""); // I
  const [biayaTarik, setBiayaTarik] = useState(""); // I
  const [total, setTotal] = useState("");

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTermJual, setSearchTermJual] = useState("");
  const [openJual, setOpenJual] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermJual(query);
  };

  const classes = useStyles();

  const handleClickOpenJual = () => {
    setOpenJual(true);
  };

  const handleCloseJual = () => {
    setOpenJual(false);
  };

  function dhm(t) {
    var cd = 24 * 60 * 60 * 1000,
      ch = 60 * 60 * 1000,
      d = Math.floor(t / cd),
      h = Math.floor((t - d * cd) / ch),
      m = Math.round((t - d * cd - h * ch) / 60000),
      pad = function (n) {
        return n < 10 ? "0" + n : n;
      };
    if (m === 60) {
      h++;
      m = 0;
    }
    if (h === 24) {
      d++;
      h = 0;
    }
    return d;
  }

  function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  const kolektorOptions = kolektors.map((kolektor) => ({
    label: `${kolektor.kodeKolektor} - ${kolektor.namaKolektor}`,
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getStNextKode(inputTglSt);
    getKolektor();
  }, []);

  useEffect(() => {
    getJual();
  }, [page, searchTermJual]);

  const getStNextKode = async (date) => {
    const nextKodeSt = await axios.post(`${tempUrl}/stsNextKode`, {
      tglSt: date,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setNoSt(nextKodeSt.data);
  };

  const getKolektor = async () => {
    setLoading(true);
    const allKolektors = await axios.post(`${tempUrl}/kolektors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKolektors(allKolektors.data);
    setLoading(false);
  };

  const getJual = async () => {
    const allJualsForAngsuran = await axios.post(
      `${tempUrl}/jualsForAngsuranPagination?search_query=${searchTermJual}&page=${page}&limit=${limit}`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setJuals(allJualsForAngsuran.data.juals);
    setPage(allJualsForAngsuran.data.page);
    setPages(allJualsForAngsuran.data.totalPage);
    setRows(allJualsForAngsuran.data.totalRows);
  };

  const saveSt = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    let tglSt =
      inputTglSt.getFullYear() +
      "-" +
      (inputTglSt.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      inputTglSt.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });

    let isFailedValidation =
      noSt.length === 0 ||
      inputTglSt === null ||
      noJual.length === 0 ||
      kodeKolektor.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        const tempKolektor = await axios.post(`${tempUrl}/kolektorByKode`, {
          kodeKolektor,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        // Find Jual
        const response = await axios.post(`${tempUrl}/jualByNoJual`, {
          noJual,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        // Find Angsuran
        const angsuran = await axios.post(`${tempUrl}/angsuransByNoJual`, {
          noJual,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        // Find Angsuran Terakhir
        const findAngsuranTerakhir = await axios.post(
          `${tempUrl}/angsuransChild`,
          {
            noJual,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        // Save St Transaction
        await axios.post(`${tempUrl}/saveStTransaction`, {
          jualData: {
            idJual: response.data._id,
            tglStTerakhir: tglSt,
            kodeKolektor: tempKolektor.data._id,
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          },
          stData: {
            noAngsuran: findAngsuranTerakhir.data._id,
            noSt,
            tglSt,
            noJual,
            idJual: response.data._id,
            kodeKolektor: tempKolektor.data._id,
            angPerBulan,
            jmlBlnTelat,
            totalDenda,
            biayaTarik,
            tglInput: current_date,
            jamInput: current_time,
            userInput: user.username,
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          },
          angsuranData: {
            idAngsuran: angsuran.data._id,
            angsuranKe: findAngsuranTerakhir.data._id - 1,
            biayaTarik,

            _id: findAngsuranTerakhir.data._id,
            tglJatuhTempo: findAngsuranTerakhir.data.tglJatuhTempo,
            angModal: findAngsuranTerakhir.data.angModal,
            angBunga: findAngsuranTerakhir.data.angBunga,
            angPerBulan: findAngsuranTerakhir.data.angPerBulan,
            tglBayar: findAngsuranTerakhir.data.tglBayar,
            noKwitansi: findAngsuranTerakhir.data.noKwitansi,
            keterangan: findAngsuranTerakhir.data.keterangan,
            denda: findAngsuranTerakhir.data.denda,
            potongan: findAngsuranTerakhir.data.potongan,
            jemputan: 0,
            hutangDenda: findAngsuranTerakhir.data.hutangDenda,
            totalPiutang: findAngsuranTerakhir.data.totalPiutang,
            totalBayar: findAngsuranTerakhir.data.totalBayar,
            bayar: findAngsuranTerakhir.data.bayar,

            tglUpdate: current_date,
            jamUpdate: current_time,
            userUpdate: user.username,
            id: user._id,
            token: user.token,
          },
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate("/suratPenarikan");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Entry Surat Penarikan
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveSt}>
          {/* Data Customer */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA CUSTOMER
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Surat Penarikan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noSt} disabled readOnly />
                        <Form.Control.Feedback type="invalid">
                          No. ST harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Surat Penarikan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={inputTglSt}
                          onChange={(e) => {
                            setInputTglSt(e);
                            getStNextKode(e);
                            var d1 = new Date(tglJatuhTempo); //"now"
                            var d2 = new Date(e); // some date
                            var diff = Math.abs(d2 - d1); // difference in milliseconds
                            var total = dhm(diff);
                            if (d2 > d1) {
                              setDendaTunggak(total * setting.dendaSetting);
                            }
                            setJmlBlnTelat(
                              monthDiff(new Date(tglJatuhTempo), new Date(e))
                            );
                            setTotalDenda(
                              monthDiff(new Date(tglJatuhTempo), new Date(e)) *
                                angPerBulan +
                                total * setting.dendaSetting
                            );
                            setTotal(
                              setting.biayaTarikSetting +
                                monthDiff(
                                  new Date(tglJatuhTempo),
                                  new Date(e)
                                ) *
                                  angPerBulan +
                                total * setting.dendaSetting
                            );
                          }}
                          customInput={
                            <TextField
                              error={error && inputTglSt === null && true}
                              helperText={
                                error &&
                                inputTglSt === null &&
                                "Tgl. Surat Penarikan harus diisi!"
                              }
                              sx={{ width: "100%" }}
                              size="small"
                            />
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Jual <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noJual}
                          required
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenJual()}
                          onChange={(e) =>
                            setNoSt(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          No. Jual harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Eksekutor <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={kolektorOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && kodeKolektor.length === 0 && true}
                              helperText={
                                error &&
                                kodeKolektor.length === 0 &&
                                "Kode Eksekutor harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) =>
                            setKodeKolektor(value.split(" ", 1)[0])
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Telepon :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tlpRegister} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaRegister} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={almRegister} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kecamatan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeKecamatan} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Angsuran :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tglAngFormatted} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Motor */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA MOTOR
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tipe :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tipe} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Rangka :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noRangka} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tahun / Warna :
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control value={tahun} disabled />
                      </Col>
                      <Col sm={4}>
                        <Form.Control value={namaWarna} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nopol :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={nopol} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Penarikan */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA PENARIKAN
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Jatuh Tempo:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tglJatuhTempoFormatted} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Masa Tunggakan:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={`${jmlBlnTelat} bulan`} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Ang. / Bln :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={angPerBulan.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Denda Tunggakan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={dendaTunggak.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Jumlah Tunggakan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={totalDenda.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Biaya Tarik :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={biayaTarik.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Total :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={total.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/suratPenarikan")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openJual}
        onClose={handleCloseJual}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Jual`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Jual
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nama Register
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tanggal
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Plat
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Alamat</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {juals.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNoJual(user.noJual);
                        setNamaRegister(user.namaRegister);
                        setAlmRegister(user.almRegister);
                        setTglAng(user.tglJatuhTempo);
                        setTglAngFormatted(formatDate(user.tglJatuhTempo));
                        setKodeKecamatan(
                          `${user.kodeKelurahan.kodeKecamatan} - ${user.kodeKelurahan.kodeKecamatan}`
                        );
                        setAngPerBulan(user.angPerBulan);
                        setTlpRegister(user.tlpRegister);
                        setTipe(user.tipe);
                        setNoRangka(user.noRangka);
                        setNamaWarna(user.namaWarna);
                        setTahun(user.tahun);
                        setNopol(user.nopol);
                        setTglJatuhTempo(user.tglJatuhTempo);
                        setTglJatuhTempoFormatted(
                          formatDate(user.tglJatuhTempo)
                        );
                        setJmlBlnTelat(
                          monthDiff(
                            new Date(user.tglJatuhTempo),
                            new Date(inputTglSt)
                          )
                        );
                        setBiayaTarik(setting.biayaTarikSetting);

                        var d1 = new Date(user.tglJatuhTempo); //"now"
                        var d2 = new Date(inputTglSt); // some date
                        var diff = Math.abs(d2 - d1); // difference in milliseconds
                        var total = dhm(diff);
                        if (d2 > d1) {
                          setDendaTunggak(total * setting.dendaSetting);
                        }

                        setTotalDenda(
                          monthDiff(
                            new Date(user.tglJatuhTempo),
                            new Date(inputTglSt)
                          ) *
                            user.angPerBulan +
                            total * setting.dendaSetting
                        );
                        setTotal(
                          setting.biayaTarikSetting +
                            monthDiff(
                              new Date(user.tglJatuhTempo),
                              new Date(inputTglSt)
                            ) *
                              user.angPerBulan +
                            total * setting.dendaSetting
                        );

                        handleCloseJual();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noJual}
                      </TableCell>
                      <TableCell>
                        {user.tenor - user.bayarKe !== 0 ? "MASIH" : "SELESAI"}
                      </TableCell>
                      <TableCell>{user.namaRegister}</TableCell>
                      <TableCell>{user.tanggalJualFormatted}</TableCell>
                      <TableCell>{user.nopol}</TableCell>
                      <TableCell>{user.almRegister}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TambahSuratPenarikan;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
