import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableCustomer } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../components";
import {
  Box,
  Typography,
  Divider,
  Pagination,
  Button,
  ButtonGroup,
  Paper,
  TextField,
  Autocomplete,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import SaveIcon from "@mui/icons-material/Save";
import { Col, Row, Form } from "react-bootstrap";

const TampilCustomer = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const [isFetchError, setIsFetchError] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [customersData, setCustomersData] = useState([]);

  const [isCetakCustomerJual, setIsCetakCustomerJual] = useState(false);
  const [cabangInput, setCabangInput] = useState(`${user.cabang._id}`);
  const [allCabang, setAllCabang] = useState([]);
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const navigate = useNavigate();

  const columns = [
    { title: "No", field: "no" },
    { title: "No Register", field: "noRegister" },
    { title: "Nama", field: "namaRegister" },
    { title: "Alamat", field: "almRegister" },
    { title: "Telepon", field: "tlpRegister" },
    { title: "No. KTP", field: "noKtpRegister" },
  ];

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  const cabangOptions = allCabang.map((user) => ({
    label: `${user._id} - ${user.namaCabang}`,
  }));

  useEffect(() => {
    if (user.tipeUser === "MGR") {
      getAllCabang();
    }
    getCustomersData();
  }, [page, searchTerm]);

  const getAllCabang = async () => {
    setLoading(true);
    const allCabang = await axios.post(`${tempUrl}/cabangs`, {
      id: user._id,
      token: user.token,
    });
    setAllCabang(allCabang.data);
    setLoading(false);
  };

  const getCustomersData = async () => {
    setLoading(true);
    try {
      let allCustomers;
      if (user.tipeUser === "OWN/DIREKSI") {
        allCustomers = await axios.post(
          `${tempUrl}/allCustomersSemuaCabangPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            id: user._id,
            token: user.token,
          }
        );
      } else {
        allCustomers = await axios.post(
          `${tempUrl}/allCustomersPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
      }
      setQuery(searchTerm);
      setCustomersData(allCustomers.data.customers);
      setPage(allCustomers.data.page);
      setPages(allCustomers.data.totalPage);
      setRows(allCustomers.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getCustomersForDoc = async (isPdf, isExcel) => {
    setLoading(true);
    let allCustomers;
    if (user.tipeUser === "OWN/DIREKSI") {
      allCustomers = await axios.post(
        `${tempUrl}/allCustomersSemuaCabangForDoc`,
        {
          id: user._id,
          token: user.token,
        }
      );
    } else {
      allCustomers = await axios.post(`${tempUrl}/allCustomersForDoc`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
    }
    setLoading(false);
    if (isPdf) {
      downloadPdfDaftarJual(allCustomers.data);
    } else if (isExcel) {
      downloadExcel(allCustomers.data);
    }
  };

  const getAllJualsCustomerForDoc = async () => {
    setLoading(true);

    if (user.tipeUser === "OWN/DIREKSI" || user.tipeUser === "MGR") {
      let allCustomers = await axios.post(
        `${tempUrl}/allJualsCetakCustomerManager`,
        {
          id: user._id,
          token: user.token,
          // kodeCabang: user.cabang._id,
          kodeCabang: cabangInput,
        }
      );
      setLoading(false);
      downloadExcel(allCustomers.data);
    } else {
      let allCustomers = await axios.post(`${tempUrl}/allJualsCetakCustomer`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setLoading(false);
      downloadExcel(allCustomers.data);
    }
  };

  const downloadPdfDaftarJual = (data) => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Customer`, 90, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    let no = 0;
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: data.map((col) => {
        no++;
        return { ...col, no };
      }),
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadExcel = (data) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `Jual`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarCustomer.xlsx`);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Penjualan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Customer
      </Typography>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            startIcon={<PrintIcon />}
            onClick={() => getCustomersForDoc(true, false)}
          >
            CETAK
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => getCustomersForDoc(false, true)}
          >
            EXCEL
          </Button>
          <Button
            color="primary"
            startIcon={<DownloadIcon />}
            // onClick={() => getAllJualsCustomerForDoc()}
            onClick={() => {
              setIsCetakCustomerJual(!isCetakCustomerJual);
            }}
          >
            EXCEL JUAL
          </Button>
        </ButtonGroup>
      </Box>
      {isCetakCustomerJual && (
        <div style={{ marginTop: "10px" }}>
          <Form noValidate onSubmit={getAllJualsCustomerForDoc}>
            <Paper elevation={6} sx={mainContainer}>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="3" style={textRight}>
                          Cabang :
                        </Form.Label>
                        {user.tipeUser === "MGR" ? (
                          <Col sm="9">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={cabangOptions}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  placeholder="SEMUA CABANG"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                setCabangInput(value.split(" ", 1)[0]);
                              }}
                              value={cabangInput}
                            />
                          </Col>
                        ) : (
                          <Col sm="9">
                            <Form.Control value={cabangInput} disabled />
                          </Col>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Box sx={spacingTop}>
                    <Button
                      variant="contained"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      Simpan Excel
                    </Button>
                  </Box>
                </Box>
                <Box sx={[showDataWrapper, secondWrapper]}></Box>
              </Box>
            </Paper>
          </Form>
        </div>
      )}
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={null}
          addLink={`/customer/tambahCustomer`}
          editLink={null}
          deleteUser={null}
          nameUser={null}
        />
      </Box>
      <Divider sx={dividerStyle} />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableCustomer
          currentPosts={customersData}
          tipeUser={user.tipeUser}
        />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilCustomer;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw",
  // },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const pilihCetakContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: 2,
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
};
