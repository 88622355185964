import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  ButtonGroup,
  Button,
  Divider,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const LapSuratPemberitahuan = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  var date = new Date();
  let [inputDariTgl, setInputDariTgl] = useState(date);
  let [inputSampaiTgl, setInputSampaiTgl] = useState(date);
  const [grouping, setGrouping] = useState("PER TANGGAL");
  const [jenisSuratPemberitahuan, setJenisSuratPemberitahuan] =
    useState("SEMUA");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [surveyors, setSurveyors] = useState([]);
  const [kodeKolektor, setKodeKolektor] = useState("");
  const [kolektors, setKolektors] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleChangeGrouping = (event) => {
    setGrouping(event.target.value);
  };

  const handleChangeLaporan = (event) => {
    setJenisSuratPemberitahuan(event.target.value);
  };

  const columns = [
    { title: "No", field: "no" },
    { title: "No. Kontrak", field: "noJual" },
    { title: "Tanggal", field: "tglSp" },
    { title: "Nama Customer", field: "namaRegister" },
    { title: "Tipe", field: "tipe" },
    { title: "No. Plat", field: "nopol" },
    { title: "Thn", field: "tahun" },
    { title: "Angsuran", field: "angPerBulan" },
    { title: "Bln", field: "sisaBulan" },
    { title: "Denda", field: "dendaText" },
    { title: "H. Denda", field: "hutangDendaText" },
    { title: "Surveyor", field: "surveyor" },
    { title: "Eksekutor", field: "kolektor" },
  ];

  const surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  const kolektorOptions = kolektors.map((kolektor) => ({
    label: `${kolektor.kodeKolektor} - ${kolektor.namaKolektor}`,
  }));

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    getSurveyor();
    getKolektor();
  }, []);

  const getSurveyor = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(response.data);
    setLoading(false);
  };

  const getKolektor = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/kolektors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKolektors(response.data);
    setLoading(false);
  };

  const downloadPdf = async (data) => {
    setLoading(true);
    let tempSubGroupHeight = 35;

    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF("l", "mm", [410, 210]);
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Laporan Surat Pemberitahuan`, 170, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      205
    );
    doc.setFontSize(12);
    let no = 0;
    // doc.autoTable({
    //   startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
    //   columns: columns.map((col) => ({ ...col, dataKey: col.field })),
    //   body: data.map((col) => {
    //     no++;
    //     return { ...col, no };
    //   }),
    //   headStyles: {
    //     fillColor: [117, 117, 117],
    //     color: [0, 0, 0],
    //   },
    // });

    for (var i = 0; i < Object.keys(data).length; i++) {
      doc.setFontSize(10);
      if (grouping === "PER TANGGAL") {
        doc.text(
          `Tanggal : ${Object.values(data)[i][0].tglSp}`,
          15,
          tempSubGroupHeight
        );
      } else if (grouping === "PER EKSEKUTOR") {
        doc.text(
          `Kolektor : ${Object.values(data)[i][0].kolektor}`,
          15,
          tempSubGroupHeight
        );
      } else if (grouping === "PER SURVEYOR") {
        doc.text(
          `Surveyor : ${Object.values(data)[i][0].surveyor}`,
          15,
          tempSubGroupHeight
        );
      }

      doc.autoTable({
        // startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
        startY: tempSubGroupHeight + 5,
        columns: columns.map((col) => ({ ...col, dataKey: col.field })),
        body: Object.values(data)[i].map((col) => {
          no++;
          return { ...col, no };
        }),
        headStyles: {
          fillColor: [117, 117, 117],
          color: [0, 0, 0],
        },
      });
    }

    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
    setPersenLoading("0");
  };

  const getLaporansForDoc = async (isPdf, isExcel) => {
    setLoading(true);
    if (isPdf) {
      let tempPersenLoading = parseInt(persenLoading);
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());

      const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
        kodeSurveyor,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      const tempKolektor = await axios.post(`${tempUrl}/kolektorByKode`, {
        kodeKolektor,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      setTimeout(() => {
        tempPersenLoading += 10;
        setPersenLoading(tempPersenLoading.toString());
      }, "5000");

      setTimeout(() => {
        tempPersenLoading += 10;
        setPersenLoading(tempPersenLoading.toString());
      }, "10000");

      setTimeout(() => {
        tempPersenLoading += 10;
        setPersenLoading(tempPersenLoading.toString());
      }, "15000");

      setTimeout(() => {
        tempPersenLoading += 10;
        setPersenLoading(tempPersenLoading.toString());
      }, "20000");

      const response = await axios.post(`${tempUrl}/laporanSps`, {
        dariTgl: inputDariTgl,
        sampaiTgl: inputSampaiTgl,
        kodeSurveyor: tempSurveyor.data ? tempSurveyor.data._id : null,
        kodeKolektor: tempKolektor.data ? tempKolektor.data._id : null,
        grouping,
        jenisSuratPemberitahuan,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());

      let data = groupBy(response.data, "tglSp");
      if (grouping === "PER EKSEKUTOR") {
        data = groupBy(response.data, "kolektor");
      } else if (grouping === "PER SURVEYOR") {
        data = groupBy(response.data, "surveyor");
      }

      // downloadPdf(response.data);
      downloadPdf(data);
    } else if (isExcel) {
      let tempPersenLoading = parseInt(persenLoading);
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());

      const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
        kodeSurveyor,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      const tempKolektor = await axios.post(`${tempUrl}/kolektorByKode`, {
        kodeKolektor,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      setTimeout(() => {
        tempPersenLoading += 10;
        setPersenLoading(tempPersenLoading.toString());
      }, "5000");

      setTimeout(() => {
        tempPersenLoading += 10;
        setPersenLoading(tempPersenLoading.toString());
      }, "10000");

      setTimeout(() => {
        tempPersenLoading += 10;
        setPersenLoading(tempPersenLoading.toString());
      }, "15000");

      setTimeout(() => {
        tempPersenLoading += 10;
        setPersenLoading(tempPersenLoading.toString());
      }, "20000");

      const response = await axios.post(`${tempUrl}/laporanSpsForExcel`, {
        dariTgl: inputDariTgl,
        sampaiTgl: inputSampaiTgl,
        kodeSurveyor: tempSurveyor.data ? tempSurveyor.data._id : null,
        kodeKolektor: tempKolektor.data ? tempKolektor.data._id : null,
        grouping,
        jenisSuratPemberitahuan,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      downloadExcel(response.data);
      setLoading(false);
      setPersenLoading("0");
    }
    setLoading(false);
  };

  const downloadExcel = (data) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `LaporanSP`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarLaporanSP.xlsx`);
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan Surat Pemberitahuan
      </Typography>
      <Divider sx={dividerStyle} />
      <div>
        <FormControl sx={{ marginTop: 1 }}>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Grouping
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            defaultValue="SURVEYOR"
            value={grouping}
            onChange={handleChangeGrouping}
          >
            <FormControlLabel
              value="PER TANGGAL"
              control={<Radio />}
              label="Per Tanggal"
            />
            <FormControlLabel
              value="PER EKSEKUTOR"
              control={<Radio />}
              label="Per Eksekutor"
            />
            <FormControlLabel
              value="PER SURVEYOR"
              control={<Radio />}
              label="Per Surveyor"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <FormControl sx={{ marginTop: 1 }}>
          <FormLabel id="demo-controlled-radio-buttons-group">Jenis</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            defaultValue="SEMUA"
            value={jenisSuratPemberitahuan}
            onChange={handleChangeLaporan}
          >
            <FormControlLabel value="SEMUA" control={<Radio />} label="Semua" />
            <FormControlLabel
              value="BELUM BAYAR"
              control={<Radio />}
              label="Belum Bayar"
            />
            <FormControlLabel
              value="SUDAH BAYAR"
              control={<Radio />}
              label="Sudah Bayar"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <hr />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="8">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputDariTgl}
                  onChange={(e) => setInputDariTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputDariTgl === null && true}
                      helperText={
                        error &&
                        inputDariTgl === null &&
                        "Dari Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Sampai Tanggal :
              </Form.Label>
              <Col sm="8">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputSampaiTgl}
                  onChange={(e) => setInputSampaiTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputSampaiTgl === null && true}
                      helperText={
                        error &&
                        inputSampaiTgl === null &&
                        "Sampai Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Kode Surveyor :
              </Form.Label>
              <Col sm="8">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={surveyorOptions}
                  defaultValue={kodeSurveyor}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      error={error && kodeSurveyor.length === 0 && true}
                      helperText={
                        error &&
                        kodeSurveyor.length === 0 &&
                        "Kode Surveyor harus diisi!"
                      }
                      {...params}
                    />
                  )}
                  onInputChange={(e, value) =>
                    setKodeSurveyor(value.split(" ", 1)[0])
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Kode Eksekutor :
              </Form.Label>
              <Col sm="8">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={kolektorOptions}
                  defaultValue={kodeKolektor}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      error={error && kodeKolektor.length === 0 && true}
                      helperText={
                        error &&
                        kodeKolektor.length === 0 &&
                        "Kode Eksekutor harus diisi!"
                      }
                      {...params}
                    />
                  )}
                  onInputChange={(e, value) =>
                    setKodeKolektor(value.split(" ", 1)[0])
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            variant="contained"
            startIcon={<PrintIcon />}
            onClick={() => {
              getLaporansForDoc(true, false);
            }}
          >
            CETAK
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => {
              getLaporansForDoc(false, true);
            }}
          >
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default LapSuratPemberitahuan;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};
